.c_paginator {
  &.wrapper {
    flex: 1;
    display: flex;
    overflow: hidden;
    flex-direction: column;
  }

  .wrapper-items {
    flex: 1;
    display: flex;
    overflow: hidden;
    width: min-content;
    align-items: stretch;
  }

  .item {
    width: 100vw;
    overflow-y: auto;
    flex-shrink: 0;
  }
}
