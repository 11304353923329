.start {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .background {
    z-index: -1;
    width: 100%;
    height: 100%;
    margin: -5px;
    filter: blur(5px);
    position: absolute;
    background: url('/assets/svg/imagem-bike.svg') no-repeat center;
    background-size: cover;
  }

  .spacer {
    height: 40%;
  }

  .wrapper_text {
    display: flex;
    align-self: stretch;
    align-items: center;
    background: white;
    flex-direction: column;
  }
}
