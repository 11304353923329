.formulario {
  &.container {
    flex: 1;
    margin: 0;
    display: flex;
    max-width: unset;
    flex-direction: column;
    overflow: hidden;
  }

  .header {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #f1faff;
    margin: 0 ($spacer * -1.5);

    .title {
      margin: 0;
      line-height: 1;
      font-size: 1.1rem;
    }
  }

  .header-alt {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin: 0 ($spacer * -1.5);

    .destaque {
      margin: 0;
      width: 100%;
      color: $primary;
      font-size: 1.5rem;
      font-weight: bold;
    }

    .wrapper {
      align-self: center;
      width: calc(100% + 3rem);
      background-color: $primary;
    }

    .title {
      margin: 0;
      line-height: 1;
      color: white;
      font-size: 1rem;
    }
  }

  .content {
    .group-row {
      display: flex;

      .form-group {
        flex: 1;
      }
    }

    .wrapper_banner {
      width: $spacer * 12;
      height: $spacer * 18;
      display: flex;
      align-items: center;
      flex-direction: column;
      background-color: $primary;
      box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);

      .wrapper_qr {
        flex: 1;
        display: flex;
        align-self: stretch;
        align-items: center;
        justify-content: center;
        background-color: white;
      }
    }

    .wrapper-icon {
      width: auto;
      height: 50%;
      background-color: $primary;
      margin: -$spacer $spacer * -1.5 0 $spacer * -1.5;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .categoria {
      display: flex;
      flex-direction: column;
      box-shadow: 0px 0px 5px 0.5px rgba(0, 0, 0, 0.1);

      .label {
        width: 100%;
        font-size: 0.8rem;
      }
    }

    .modalidade {
      display: flex;
      font-size: 0.8rem;
      font-weight: bold;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0px 0px 5px 0.5px rgba(0, 0, 0, 0.1);
    }

    .coberturas {
      .title {
        font-size: 0.8rem;
        font-weight: bold;
      }

      .descricao {
        font-size: 0.8rem;
      }

      .mes {
        font-size: 0.6rem;
        color: gray('500');
      }

      .check {
        width: 3rem;
        flex-shrink: 0;
        align-self: stretch;
        background-color: $primary;
        justify-content: center;
        align-items: center;
      }

      .slider-text {
        font-size: 0.8rem;
        color: $primary;
        font-weight: bold;
      }
    }

    .resumo {
      .list_item {
        display: flex;
        align-items: center;
        box-shadow: 0px 0px 5px 0.5px rgba(0, 0, 0, 0.1);
      }

      .text {
        font-size: 0.9rem;
      }

      .text_franquia {
        font-size: 0.7rem;
      }

      .suffix {
        font-size: 0.6rem;
        color: gray('500');
      }
    }
  }

  .overflow {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
    align-items: center;
    justify-content: center;
    background-color: white;
  }

  .footer {
    display: flex;
    .btn {
      flex: 1;
    }
  }

  .text-payment-method {
    font-weight: bold;
    padding: 15px 20px;
    color: grey;
    border: 1px solid grey;
    font-size: smaller;

    &.selected {
      color: #0033a0 !important;
      border: 2px solid #0033a0;
    }
  }
}
