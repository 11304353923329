.c_input {
  .input {
    border: none;
    font-weight: bold;
    box-shadow: 0px 0px 5px 0.5px rgba(0, 0, 0, 0.1);
  }

  .input_alt {
    @extend .input;
    @extend .user-select-none;
    height: auto;
    font-weight: normal;

    &[disabled] {
      @extend .form-control:disabled;
    }

    .label {
      width: 100%;
      font-size: 0.8rem;
    }

    .clear {
      border: none;
      font-weight: bold;
      background-color: transparent;

      &:focus,
      &:invalid {
        box-shadow: none;
      }
      &:disabled {
        background-color: transparent;
      }
    }
  }

  .loading {
    top: 40%;
    bottom: 0;
    position: absolute;
    right: $spacer * 0.5;
  }
}
