.autosuggest {
  @extend .form-control;
  position: relative;
  height: auto;
  border: none;
  display: flex;
  flex-direction: column;
  min-height: calc(1.5em + 0.75rem + 2px);
  box-shadow: 0px 0px 5px 0.5px rgba(0, 0, 0, 0.1);

  .label {
    width: 100%;
    font-size: 0.8rem;
  }

  .input {
    @extend .form-control;
    border: none;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  .item {
    cursor: pointer;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
  }
}
