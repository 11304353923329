.not-mobile {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .background {
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    background: url('/assets/img/no_mobile.jpg') no-repeat center;
    background-size: cover;
  }

  .row {
    background-color: white;
  }
}
